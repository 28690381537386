import { Button, Grid, Dialog, DialogContent, Typography, Box, IconButton, SvgIcon } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// Components
import DialogHeader from '../DialogHeader'

// Icons
import CloseIcon from '@mui/icons-material/Close'

// Images
import Logo from '../../assets/logo.svg'

// Main component
export default function MobileApp(props) {
  // Vars
  const theme = useTheme()
  const URL = 'https://apps.apple.com/us/app/begerz-social-crowdfunding/id1632348045'

  return (
    <Dialog
      maxWidth="md"
      open={props.dialogOpen}
      fullWidth
    >
      <DialogContent sx={{ mb: 2 }}>
        <IconButton
          onClick={() => {props.close()}}
          aria-label="close"
          sx={{
            zIndex: 1,
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>


        {/* info section */}

        <Grid container p={1} spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} sx={{ textAlign:"center" }} >
            <img alt="logo" src={Logo} />
          </Grid>
          <Grid item xs={12} sx={{  textAlign:"center" }}>
            <Typography variant='bodytext'> Experience the full Begerz Community! </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} mt={1}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={
                () => {
                  props.close()
                  window.open(URL, '_blank');
                }
              }
            >
              Get the APP!
            </Button>
          </Grid>
        </Grid>

      </DialogContent>
    </Dialog>
  )
}
import { useState } from 'react'
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Button, Divider, FormControl, IconButton } from "@mui/material"

// Custom components
import DialogHeader from "../../../components/DialogHeader"
import Player from '../../../components/player/Player'


// Icons
import CloseIcon from '@mui/icons-material/Close'
import React from "react"


// Main component
export default function VideoModal(props) {
  // Vars
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { open, handleClose, src, title } = props

  let streams = []
  streams[0] = { videoLink:src }

  console.log(streams)


  // Methods

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
    >

      <DialogContent sx={{ mb:2 }}>
        <DialogHeader
          id="alert-dialog-title"
          sx={{ textAlign:'center', mb:3 }}
        >
          { title || 'Video' }
        </DialogHeader>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>


        <DialogContentText sx={{ mt:4, mb:4 }}>
          <FormControl fullWidth>

          { <Player controls playsinline streams={ streams } /> }



          </FormControl>
        </DialogContentText>

        <Divider />

        <Box sx={{ textAlign:'center' }}>
          <Button variant="contained" sx={{mt:3, mb:0, mr:1, fontWeight: 700}}
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>

      </DialogContent>
    </Dialog>
  )
}
import { useState } from 'react'
import { FormControl, MenuItem, Select } from "@mui/material"

// Apis
import { useApi } from '../../../apis/privateApiCall'


// Main component
export default function PaymethodSelect(props) {
  // Vars
  const data = props.data
  const withdrawalId = props.id
  const refresh = props.refresh
  const { execute } = useApi()

  // State
  const [selected, setSelected] = useState(props?.selected || "")
  const [error, setError] = useState({
    message:'',
    errorCode: '',
    title: ''    
  })
  
  // Methods
  const handleChange = (event) => {
    updateWithdrawal(event.target.value)
    setSelected(event.target.value)
  }

  const updateWithdrawal = async(newMethodId) => {
    try {
      const options =  {
        method: 'patch',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/admin/withdrawals/${withdrawalId}`
      }

      // send call to API
      await execute({
        editorId: localStorage.getItem('userId'),
        action: "method",
        paymethodId: newMethodId 
      }, options)

      // reload the activity log
      await refresh()

    } catch(error) {
      setError(prev => ({...prev,
        message: error.message,
        errorCode: '000',
        title: 'Error updating payout method'
      }))
    }
  }



  return (
    <FormControl fullWidth>
      <Select
        sx={{ fontSize:"13px", fontWeight:"600" }}
        name="paytypeSelect"
        value={selected}
        onChange={handleChange}
      >
        { data.map((item, index) => {
          return ( 
            <MenuItem key={index}
              sx={{ fontSize:"13px", fontWeight:"550" }}
              value={item._id}>{`(${item.paytype.toUpperCase()}) ${item.description}`}
            </MenuItem> 
          ) 
        })}

      </Select>
    </FormControl>
  )
}
import { Avatar } from '@mui/material'


// Vars
const useLetter = true

// Get a color based on the first letter of first name
const stringToColor = (string) => {
  let hash = 0
  let color = '#'
  let i
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2)
  }
  return color
}

// return a color
function avatarColor(name) {
  if ( name.slice(0,1) === 'A' && name.slice(1,2) > 'A') name = 'AA'
  return { bgcolor: stringToColor(name) }
}

// Main component
export default function ProfileAvatar({...props}) {
  // Vars
  let initials = '?FF'

  // check for an anonymous user
  if (!props.alt) props.alt = 'anonymous'

  // set missing image to be a letter or generic icon
  if (!props?.src) props.src = 'undefined'
  
  // format image alt property and initials
  initials = props.alt.toString().substring(0,2)
  props.alt = initials.toUpperCase()
  
  // set a color for the avatar
  props.sx = {...props.sx, ...avatarColor(initials) }
  
  return (
    <Avatar
      style={{ borderRadius:"6px" }}
      {...props} 
    />
  )
}
import { useContext, useEffect, useRef } from 'react'
import { Switch, Button, Checkbox, FormControlLabel, FormGroup, Grid, Slider, TextField, Typography } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useTheme } from '@mui/material/styles'

// Context
import { ChipInContext } from '../ChipIn'

// Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import currFormat from '../../../utils/currFormat'


export default function StepOne() {
  // Use context
  const { 
    totals,
    setTotals,
    goNextPage
  } = useContext(ChipInContext)
  
  // Vars
  const payFees = useRef()
  const amountRef = useRef()
  const sliderRef = useRef()
  const theme = useTheme()

  // Form validation react-hook-form
  const {
    setValue,
    getValues,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
      mode: 'onChange',
      defaultValues: {
        amount: totals.amount
      }
  })

  // reutrn slider text value for display
  const valuetext = (value) => {
    return `${value}`
  }

  const setUseCustomAmount = (bool) => {
    setTotals( prev => ({...prev, 'customAmount': bool}))
  }

  // calculate the fees and total
  const calcTotals = () => {
    const amount = parseFloat(amountRef.current.value) || 0.00
    let fees = (0).toFixed(2) || 0.00
    let total = (amount).toFixed(2) || 0.00
    
    // calc fees if donor selected to pay them
    if (amount > 0 && (payFees.current === true || (payFees.current === undefined && totals.payFees === true) )) {
      fees = ((amount + .30) * .0298 + .30 ).toFixed(2)
      total = (amount + parseFloat(fees)).toFixed(2)
    }

    setTotals(prev => ({...prev, amount:amount, fees:fees, total:total}) )
  }

  // slider amount change - calc new totals
  const handleSliderChange = (event) => {
    setValue('amount', event.target.value, { shouldValidate: true })
    calcTotals()
  }

  // calc fees if checked
  const handleFeesCheck = (event) => {
    setTotals(prev => ({...prev, payFees: event.target.checked }) )
    payFees.current = event.target.checked
    calcTotals()
  }

  // toggle custom amount field and slider
  const handleCustomAmount = () => {
    if (!totals.customAmount) {
      setUseCustomAmount(true)
      const amount = amountRef.current
      amount.disabled=false
      amount.focus()
    } else {
      setUseCustomAmount(false)
      amountRef.current.disabled=true
      const slider = sliderRef.current
      setValue('amount', slider.innerText, { shouldValidate: true })
    }

    calcTotals()
  }
  
  // toggle anonymous support
  const handleAnonClick = (event) => {
    setTotals(prev => ({...prev, anonymous:event.target.checked}))
  }

  // Form submission
  const onSubmit = async (data) => {
    goNextPage()
  }

  // use a react-form watch for amount changes
  const amount = useWatch({
    control,
    name:'amount'
  })

  // Effects
  useEffect(() => {
    setTotals(prev => ({...prev, amount:getValues('amount')}))
    calcTotals()
    setTotals(prev => ({...prev, initCalc:true}))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[amount])

  // custom amount
  useEffect(() => {
    amountRef.current.disabled=!totals.customAmount
    if (totals.customAmount) {
      amountRef.current.focus()      
    }
  },[totals.customAmount])
  

  return (
    <>    
      <Grid container>

        <Grid item xs={12}>
          { !totals.customAmount &&
          <Button
            variant='text'
            onClick={ () => handleCustomAmount() }
            sx={{ p:0, mt:1.8, display:'block', float:'right', color:theme.palette.primary.main }}>
            Enter Custom Amount
          </Button>                    
          }
          { totals.customAmount &&
            <Button 
              variant='text'
              onClick={ () => handleCustomAmount() }
              sx={{ p:0, mt:1.8, display:'block', float:'right', color:theme.palette.primary.main }}>
              Use Amount Slider
            </Button>                    
          }
        </Grid>


        {/* Amount slider */}

        <Grid item xs={12}>
          <Slider sx={{ mt:6 }}
            ref={sliderRef}
            disabled={ totals.customAmount }
            aria-label="Donation amount"
            value={ (!totals.customAmount && totals.amount) || 10.00 }
            getAriaValueText={valuetext}
            step={5}
            valueLabelDisplay="on"
            marks
            min={5}
            max={50}
            onChange={ handleSliderChange }
          />

          <Typography variant='caption' sx={{ mb:5 }}>
            Minimum amount is $5.00
          </Typography>
        </Grid>

      </Grid>
        
      <form onSubmit={handleSubmit(onSubmit)} style={{ width:"100%" }} noValidate>

        {/* amount form */}

        <Grid container spacing={2} alignItems="baseline">
          <Grid item xs={12}>
            <TextField sx={{ display:'inline-block' }}
              inputRef={amountRef}
              name="amount"
              label="Amount"
              required
              autoComplete="amount"
              fullWidth
              error={!!errors.amount || null}
              helperText={errors?.amount ? errors.amount.message : null}
              onChange={(event) => currFormat(event.target.value, 2) }
              {...register('amount', { 
                required: "Required",
                min: { value: 5, message: "Min is $5.00" },
                max: { value: 10000, message: "Max $10,000.00" },
                pattern: {
                  value: /^[0-9.]*$/,
                  message: 'Numbers only'
                }
              })}
            />
          </Grid>

          {/* 
          <Grid item xs={12}>
            <FormGroup sx={{ mt:-2 }} >
              <FormControlLabel
                control={<Checkbox checked={totals.payFees} onChange={handleFeesCheck} />} 
                label={
                  <Typography variant='subtitle2'>
                    I'd like to cover the fees so this Beger gets more of my contribution
                  </Typography>
                }
              />
            </FormGroup>
          </Grid>
          */}

        </Grid>

        <div className='end-date-flex-container'>
          <div className='end-date-flex-item end-date-item-text'>
            <FormGroup sx={{ mt:2 }}>
              <Typography variant='small'>Privacy</Typography>
              <FormControlLabel control={<Switch checked={totals.anonymous} onChange={handleAnonClick} />} 
                label={
                  <Typography variant='subtitle2'>
                    Make my support anonymous
                  </Typography>
                }
              />
              </FormGroup>
          </div>
          <div className='end-date-flex-item end-date-item-icon'>
            {/* InfoOutlinedIcon style={{ marginTop:'32px' }}/> */}
          </div>
        </div>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt:1, fontWeight: 700 }}
        >
          Chip In
        </Button>      
      </form>
    </>
  )
}
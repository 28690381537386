import { Grid } from '@mui/material'
import { useState, useEffect } from 'react'

// APIs
import { useApi } from '../../apis/privateApiCall'

// Images
import Facebook from '../../assets/facebook.svg'


// Main component
export default function Adverts(props) {
  // Vars
  const { execute, controller } = useApi()

  // State
  const [data, setData] = useState()

  // Methods
  // open a new tab with the advert link
  const handleClick = (data) => {
    if (data?.advertLink) window.open(data.advertLink, '_blank')
  }

  // set the ad's cursor
  const getCursor = (data) => {
    if (data?.advertLink) return "pointer"
    return "arrow"
  }


  // Load advertisement
  useEffect(() => {
    const fetchData = async () => {
      try { 
        const options = {
          method: 'get',
          baseURL: process.env.REACT_APP_API_SERVER_URL,
          url: `/api/v1/adverts/random`
        }
        setData(await execute({}, options))
      } 
      catch(error) {} 
      finally { if (props?.doneLoading) props.doneLoading() }
    }
    fetchData()
    return controller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Grid container>
      {/* 
        data && 
        <Grid item xs={12}
          sx={{ cursor: getCursor(data) }}
          onClick = { () => {handleClick(data)} }
        >
          { <img alt="related" width="100%" src={data?.webImageLink} {...props} /> }
        </Grid>
    */}
    </Grid>
  )
}
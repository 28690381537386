import React, { useEffect } from "react";
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
import { useLocation } from "react-router-dom";

// Components
import SignIn from '../../signin/SignIn';
import ExploreMenu from './ExploreMenu';
import NavSearch from './NavSearch';

// Menu components
import MobileDrawer from './MobileDrawer';
import SignedInItems from '../menu-items/SignedInItems';
import SignedOutItems from '../menu-items/SignedOutItems';

// Context
import { AuthContext } from '../../../context/Auth';

// Icons
import logo from '../../../assets/logo.svg';

ReactGA.initialize('UA-234553475-1');

// Main component
export default function Menu() {
  // Context
  const {
    loggedIn,
    signInOpen,
    handleSignIn,
    handleCloseSignin,
  } = useContext(AuthContext);

  const location = useLocation();

  useEffect(() => {
    // track pageview with gtag / react-ga / react-ga4, for example:
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);


  return (
    <Grid container spacing={1}
      alignItems="center" justifyContent="space-between"
      pt={1} mb={6} ml='auto' mr='auto'
      sx={{ maxWidth: '1020px' }}
    >

      {/* left side - hamburger menu */}

      <Grid item xs sx={{ textAlign: 'left', display: { xs: 'flex', md: 'none' } }}>
        <Grid container>
          <Grid item xs>
            <MobileDrawer />
          </Grid>
        </Grid>
      </Grid>


      {/* desktop menu - left */}

      <Grid item xs={4} sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Grid container
          direction="row"
          justifyContent="flex-start"
          alignItems="space-between"
        >

          {/* search */}

          <Grid item xs={3}>
            <NavSearch />
          </Grid>

          {/* explore */}

          <Grid item sx={{ textAlign: 'left' }}>
            <ExploreMenu />
          </Grid>

          <Grid item sx={{ textAlign: 'left' }}>
            <Button
              onClick={() => { window.open('https://www.begerz.com', '_blank'); }}
              disableRipple
              color='primary'
              sx={{ display: 'block' }}
            >
              Home
            </Button>
          </Grid>
        </Grid>
      </Grid>


      {/* center - logo */}

      <Grid item xs sx={{ textAlign: { xs: 'left', md: 'center' } }}>
        <Link to="/home">
          <img src={logo} alt="Begerz" />
        </Link>
      </Grid>


      { /* right side menu */}

      <Grid item xs={4} sx={{ textAlign: 'left', display: { xs: 'none', md: 'flex' } }}>
        <Grid container
          alignContent="right"
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {signInOpen && <SignIn closeFunction={handleCloseSignin} />}
          {!loggedIn && <SignedOutItems />}
          {loggedIn && <SignedInItems />}

        </Grid>
      </Grid>


      {/* mobile sign in link */}

      <Grid item xs sx={{ textAlign: 'right', display: { xs: 'flex', md: 'none' } }}>
        <Grid container>
          <Grid item xs>
            {loggedIn && <SignedInItems />}
            {!loggedIn &&
              <Button
                disableRipple
                variant="outlined"
                color='primary'
                sx={{ textAlign: 'right', mr: 1 }}
                onClick={handleSignIn}
              >
                Sign&nbsp;In
              </Button>
            }
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
}
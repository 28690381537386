import React from 'react'
import WaitOverlay from '../components/WaitOverlay'
import ErrorDialog from '../components/ErrorDialog'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Button, Paper } from '@mui/material'

// Custom components
import BegPagination from '../components/raises/BegPagination'
import PageTitle from '../components/styled-ui/PageTitle'
import SectionDivider from '../components/styled-ui/SectionDivider'

// Bottom content widgets
import BottomCards from '../components/BottomCards'
import FeaturedSuccess from '../components/featured-success'
import AccessPageLower from '../components/AccessPageLower'


// Main component
export default function Home() {
  // State
  const [waitOpen, setWaitOpen] = React.useState(true)
  const [canLoad, setCanLoad] = React.useState({ trending: true})
  const [hasError, setHasError] = React.useState({
    status: false,
    message: '',
    code: 500
  })
  
  // handle load order of data sections
  const handleLoaded = (index) => {
    setWaitOpen(false)
  }

  // properties for trending beg display
  const trendingProps = {
    doneLoading:handleLoaded,
    type:'trending',
    url:`/api/v1/begs/trending?proximity`,
    pageSize:12,
    paginationType:"more",
    adverts: true,
    divider:<><SectionDivider /></>
  }


  return (
    <>
      { waitOpen && <WaitOverlay />}
      { hasError.status && <ErrorDialog message={hasError.message} setError={setHasError} errorCode={hasError.code} title={'Error retrieving raises'} /> }

      <Container component="main" maxWidth="md">

        <PageTitle>Trending Raises</PageTitle>

        <Paper elevation={0} sx={{ pt:1 }}>
          <Box sx={{ display:'flex', flexDirection:'column', alignItems:'left', padding:{ xs:1, sm:3} }} >

            {/* trending raises */}
            { canLoad?.trending && 
              <BegPagination {...trendingProps}>
                <SectionDivider>
                  <Button variant='outlined' sx={{ mt:-4, backgroundColor:"white", "&:hover": { backgroundColor: "white !important" } }}>
                    View More
                  </Button>
                </SectionDivider>
              </BegPagination>
            }

            {/* featured success stories */}
            <FeaturedSuccess />

            {/* <SectionDivider /> */}

            <AccessPageLower />

          </Box>
        </Paper>
      </Container>

      <BottomCards />
    </>
  )
}
import React from 'react'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Button, Divider, Paper } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// Custom components
import BegPagination from '../components/raises/BegPagination'
import SuccessPagination from '../components/success-stories/SuccessPagination'
import BottomCards from '../components/BottomCards'
import PageTitle from '../components/styled-ui/PageTitle'
import PageSubTitle from '../components/styled-ui/PageSubTitle'
import AccessPageLower from '../components/AccessPageLower'
import SectionDivider from '../components/styled-ui/SectionDivider'
import SectionHeaderContainer from '../components/styled-ui/SectionHeaderContainer'

// Error components
import WaitOverlay from '../components/WaitOverlay'
import ErrorDialog from '../components/ErrorDialog'

// Main component
export default function BegCategory() {
  // Vars
  const { category } = useParams()

  // State
  const [hasError, setHasError] = React.useState({
    status: false,
    message: '',
    code: 500
  })
  const [waitOpen, setWaitOpen] = React.useState(false)
  const [canLoad, setCanLoad] = React.useState({category: true})
  const [pagination, setPagination] = React.useState()
  
  // Methods
  const titleCase = (string) => {
    let newString
    newString = string[0].toUpperCase()
    newString += string.slice(1).toLocaleLowerCase()
    return newString
  }

  // handle load order of data sections
  const handleLoaded = (index) => {
    switch (index) {
      case 'category':
        setCanLoad(prev => ( {...prev, success:true} ))
        break
      default:
        setWaitOpen(false)
    }
  }

  // properties for category raises display
  const categoryProps = {
    doneLoading:handleLoaded,
    type:'category',
    url:`/api/v1/begs/categories/${category.toLowerCase()}?sort=-createdAt`,
    pageSize:12,
    paginationType:"more",
    adverts: true,
    pagination: setPagination,
    divider:<><SectionDivider /></>
  }

  // properties for success stories display
  const successProps = {
    doneLoading:handleLoaded,
    type:'success',
    url:`/api/v1/success?sort=-createdAt`,
    pageSize:12,
    paginationType:"more",
    adverts: true,
    divider:<><SectionDivider /></>
  }


  return (
    <main>
    { waitOpen && <WaitOverlay />}
    { hasError.status && <ErrorDialog message={hasError.message} setError={setHasError} errorCode={hasError.code} title={'Error retrieving begs'} /> }

    <Container component="main" maxWidth="md">
      
      <PageTitle mb={0}>{titleCase(category)} Raises </PageTitle>
      <PageSubTitle>{`Active (${pagination?.records})`}</PageSubTitle>

      <Paper elevation={0} sx={{ pt:1 }} >
        <Box sx={{ display:'flex', flexDirection:'column', alignItems:'left', padding:3 }} >
          
          {/* beg category */}
          { canLoad?.category && 
            <BegPagination {...categoryProps}>
              <SectionDivider>
                <Button variant='outlined' sx={{ mt:-4, backgroundColor:"white", "&:hover": { backgroundColor: "white !important" } }}>
                  View More
                </Button>
              </SectionDivider>
            </BegPagination>
          }


            {/* success stories */}
            <SectionHeaderContainer mt={6} mb={2} to="/success">Begerz Success Stories</SectionHeaderContainer>
            { canLoad?.success && 
              <SuccessPagination {...successProps}>
                <SectionDivider>
                  <Button variant='outlined' sx={{ mt:-4, backgroundColor:"white", "&:hover": { backgroundColor: "white !important" } }}>
                    View More
                  </Button>
                </SectionDivider>
              </SuccessPagination>
            }

          <AccessPageLower />

        </Box>
      </Paper>
      </Container>

      <BottomCards />

    </main>
  )
}
import React from 'react'
import { useTheme } from '@emotion/react'
import { Chip, Divider, Grid, Typography } from '@mui/material'

// Custom components
import ThumbnailImage from '../../../components/ThumbnailImage'

// Utils
import formatDate from "../../../utils/formatDate"

// Icons
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded'
import FlagIcon from '@mui/icons-material/Flag'
import MoreVertIcon from '@mui/icons-material/MoreVert'


// Main component
export default function Media(props) {
  // Vars
  const data = props.data
  const theme = useTheme()
  const color = theme.palette.primary.main
  const inactiveColor = theme.palette.gray.light
  
  // style for thumbnail image
  const style = { 
    objectFit:'cover',
    objectPosition:'center',
    boxShadow:`0 0 0`,
    cursor:"pointer",
    border:`1px solid ${inactiveColor}`,
    borderBottom:`2px solid ${inactiveColor}`,
    borderRadius: '4px',
    height:'80px',
    width:'80px'
  }

  // Methods
  const renderDate = (rawDate) => {
    if (rawDate) return 'Posted ' + formatDate(rawDate, 'MMM dd, u ') + " at " + formatDate(rawDate, 'h:mm a')
    return ('unknown date')
  }


  return (
    <>
      { data.map((item, index) => {
        return (
          <div key={index} >
            <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
              <Grid item>
                <ThumbnailImage src={item.thumbLink} style={style} />
              </Grid>

              <Grid item xs>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='subtitle4'>{item?.localFileName || 'unknown local filename'}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='caption'>{ renderDate(item?.createdAt) }</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='caption' pt={.8}>{ `Type: ${item?.videoType || 'beg'}` }</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container spacing={1} justifyContent="flex-end">
                  <Grid item xs>
                    <Chip label="Enabled" color="info" />
                  </Grid>

                  {/* 
                  <Grid item xs>
                    <OutlinedFlagRoundedIcon
                      sx={{ cursor:"pointer", color:color }} 
                    />
                  </Grid>
                  */}

                  <Grid item xs>
                    <MoreVertIcon sx={{ ml:2}} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ mb:1 }} />
          </div>
        )
      })}
    </>
  )
}
import { useState, useEffect } from 'react'
import { Button, Divider, Grid, Paper, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

// Custom components
import WaitOverlay from '../../../components/WaitOverlay'
import ErrorDialog from '../../../components/ErrorDialog'

// Page components
import ActivityLog  from './ActivityLog'
import FlagsGrid    from './flags-grid/index'

// Apis
import { useApi } from '../../../apis/privateApiCall'

// Utils
import capFirstLetter from '../../../utils/upperFirst'
import formatDate from '../../../utils/formatDate'
import ReplaceHashtags from '../../../utils/ReplaceHashTags'

// Icons
import LaunchIcon from '@mui/icons-material/Launch'
import LeftArrow from '../../../assets/icons/leftArrow.svg'


// Main components
export default function Details() {
  // Params
  const { flagId } = useParams()
  
  // Vars
  const { execute, controller, loading } = useApi()
  const theme = useTheme()
  const navigate = useNavigate()

  // State
  const [data, setData] = useState()
  const [logs, setLogs] = useState(false)
  const [error, setError] = useState({
    message:'',
    errorCode: '',
    title: ''    
  })


  // Methods

  // call api to get activity logs
  const getActivityLog = async () => {
    try {
      const response = await execute({}, {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/admin/flag-logs/flag/${flagId}?sort=createdAt&page=1&limit=1000`
      })
      setLogs(response)
    } catch(error) {
      throw error
    }
  }

  // toggle the flag status of the beg
  const toggleFlagged = async () => {
    try {
      await execute({
        "itemId": data.itemId,
        "begId": data.begId,
        "flagId": data._id,
        "itemType": data.itemType,
        "userId": localStorage.getItem('userId')
      },
      {
        method: 'post',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/admin/toggle-flagged-content`
      })
      // refresh the content flag data
      await fetchContentFlag()
    } catch(error) {
      throw error
    }
  }

  // fetch main data
  const fetchContentFlag = async() => {
    try {
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/content-flags/${flagId}`
      }
      // send call to API
      const response = await execute({}, options)
      setData(response)
      await getActivityLog()
    } catch(error) {
      setError(prev => ({...prev,
        message: error.message,
        errorCode: '000',
        title: 'Error getting beg'
      }))
    }
  }

  // buton text
  const getButtonText = (flagged) => {
    let text = 'Disable Content'
    if (flagged) text = 'Enable Content'
    return text
  }

  // Load Effect
  useEffect(() => {
    fetchContentFlag()
    return controller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <Container component="main" maxWidth="lg">
      
      <Typography variant="h4" sx={{ color:'white', textAlign:'left', mb:4 }}>
        {'Flagged Content > Beg'}
      </Typography>

      { loading && <WaitOverlay />}
      { error?.message && <ErrorDialog setError={setError} {...error} />}

      <Paper elevation={0}>

        <Box sx={{ p:2 }} >

          <Grid container justifyContent="space-between" alignItems="center" mt={1} mb={3}>
            <Grid item>
              <Link to="/admin/flagged-content" style={{ display:"block", textDecoration:"none", color:theme.palette.primary.main, marginBottom:"14px" }}>
                <img style={{ marginRight:'6px', verticalAlign:'middle' }} alt="left arrow" src={LeftArrow} /> Flagged Content
              </Link>
            </Grid>
            <Grid item>
              <Button variant='contained'
                onClick={toggleFlagged}
              >
                { data?._id && getButtonText(data.beg?.flagged) }
              </Button>
            </Grid>
          </Grid>


          <Grid container spacing={2}>

            {/* main section */}

            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                
                <Grid item xs={12}>

                  {/* flagged by */}

                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Paper elevation={1} sx={{ p:1 }}>
                        <Typography component="div" variant="subtitle3" mb={.5}>
                          Posted by
                          <LaunchIcon
                            sx={{ float:"right", mt:1, cursor:"pointer" }} 
                            onClick={() => navigate(`/profile/${data?.flaggedBy._id}`)}
                          />
                        </Typography>
                        <Typography component="div" variant="subtitle4">
                          { data?.flaggedBy?.firstName && capFirstLetter(data?.flaggedBy?.firstName) } { data?.flaggedBy?.lastName && capFirstLetter(data?.flaggedBy?.lastName)} 
                        </Typography>
                      </Paper>
                    </Grid>

                    <Grid item xs={4}>
                      <Paper elevation={1} sx={{ p:1 }}>
                        <Typography component="div" variant="subtitle3" mb={.5}>
                          Last updated
                        </Typography>
                        <Typography component="div" variant="subtitle4">
                          { data?.beg?.createdAt && ( formatDate(data.beg.createdAt, 'MMM dd, u ') + " at " + formatDate(data.beg.createdAt, 'h:mm a') ) }
                        </Typography>
                      </Paper>
                    </Grid>


                  </Grid>
                </Grid>


                {/* title */}

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Paper elevation={1} sx={{ p:1 }}>
                        <Typography component="div" variant="subtitle3" mb={.5}>
                          Beg title
                          <LaunchIcon
                            sx={{ float:"right", mt:1, cursor:"pointer" }} 
                            onClick={() => navigate(`/raises/details/${data?.begId}`)}
                          />
                        </Typography>
                        <Typography noWrap component="div" variant="h6">
                          { data?.beg?.title }
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>


                {/* beg story */}

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Paper elevation={1} sx={{ p:1 }}>
                        <Typography component="div" variant="subtitle3" mb={.5}>
                          Beg story
                        </Typography>
                        <Typography noWrap component="div" variant="h6">
                          { data?.beg?.htmlDescription && ReplaceHashtags(data.beg.htmlDescription) }
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>


                {/* flags section */}

                <Grid item xs={12} sx={{ mt:3 }}>
                
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Flags</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Paper elevation={1} sx={{ p:1, pt:0 }}>
                            { data?.itemId && <FlagsGrid refresh={getActivityLog} itemId={data?.itemId} /> }
                          </Paper>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


            {/* activity section */}

            <Grid item xs={12} md={3}>

              <Typography component="div" variant="subtitle3" mb={.5}>
                Activity Log
              </Typography>

              { !!logs && <ActivityLog refresh={getActivityLog} data={logs} createdAt={data?.createdAt} flagId={flagId} /> }

            </Grid>
          </Grid>

        </Box>
      </Paper>
    </Container>
  )
}

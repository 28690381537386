import { useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { EditorState, ContentState } from "draft-js"
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import { FormHelperText } from '@mui/material'

// CSS
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import '../../../css/CreateBeg.css'
import { borderBottom } from '@mui/system'


// Main Component
export default function HtmlEditor(props) {
  // Vars
  let data = props.data
  const theme = useTheme()
  const borderColor = theme.palette.gray.light
  const setData = props.setData
  
  // Convert HTML story to draft format
  const blocksFromHtml = htmlToDraft(data.htmlDescription)
  const { contentBlocks, entityMap } = blocksFromHtml
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
  const EditorRef = useRef()
  
  // min and max editor length
  const minEditorLength = process.env.REACT_APP_MIN_BEG_DESCRIPTION_LENGTH
  const maxEditorLength = process.env.REACT_APP_MAX_BEG_DESCRIPTION_LENGTH
  
  // toolbar options
  const toolbarOptions = { options: ['inline', 'list', 'textAlign'],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline']
    }}


  // State
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))
  const [errors, setErrorState] = useState({})
    
  // Focus the editor
  useEffect(() => {
    EditorRef?.current.focus()
    setErrorState({})
  },[])
  

  // Methods

  // Check for editor requirement errors
  const checkEditorErrors = (contentState) => {
    setErrorState({})
    if (!contentState || !contentState.hasText() ) {
      setErrorState({
        description: {
          error: true,
          message: 'Please tell your story'
        }
      })
      return false
    }

    // check content length
    const length = contentState?.getPlainText().length || 0
    if (length < minEditorLength) {
      setErrorState({
        description: {
          error: true,
          message: `Your story should be at least ${minEditorLength} characters`
        }
      })
      return false
    }

    if (length > maxEditorLength) {
      setErrorState({
        description: {
          error: true,
          message: `Your story cannot exceed ${maxEditorLength} characters`
        }
      })
      return false
    }
    return true
  }
 
  // check the editor length before accepting input
  const handleBeforeInput = () => {
    const contentState = editorState.getCurrentContent()
    const length = contentState?.getPlainText().length || 0
    if (length > maxEditorLength) return 'handled'
  }

  // check length of editor if text is pasted
  const handlePastedText = (pastedText) => {
    const contentState = editorState.getCurrentContent()
    const length = (contentState?.getPlainText().length || 0) + pastedText.length
    if (length > maxEditorLength) {
      setErrorState({
        description: {
          error: true,
          message: `Cannot paste. Your story cannot exceed ${maxEditorLength} characters`
        }
      })
      return 'handled'
    }
  }

  // track the state of the editor
  const handleEditorChange = (editorState) => {
    setEditorState(editorState)
    checkEditorErrors(editorState.getCurrentContent())
  }

  // handler for return and shift-return
  const handleReturn = (event) => {}


  return (
    <>
      <Editor
        id="description"
        name="description"
        wrapperClassName="wrapper"
        editorClassName="editorEdit"
        toolbar={toolbarOptions}
        hashtag={{ separator: ' ', trigger: '#' }}
        onEditorStateChange={handleEditorChange}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastedText}
        editorState={editorState}
        editorRef={(ref) => (EditorRef.current = ref)}
        wrapperStyle={{ marginTop:"4px", border:`1px solid ${borderColor}`, borderRadius:"6px" }}
        editorStyle={{ lineHeight:"50%", padding:"8px", borderTop:`1px solid ${borderColor}`, minHeight:"120px", maxHeight:"120px" }}
        toolbarStyle={{ border:"none", marginTop:"2px" }}
        handleReturn={handleReturn}
      />
      <FormHelperText sx={{ ml:1, mt:.5 }} error={ !!errors.description }>
        {(errors.description && errors.description?.message) || '(Must be between 8 and 1,000 characters)'}
      </FormHelperText>
    </>
  )
}
import { forwardRef, useState, useRef, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Button, Grid, Dialog, DialogContent, Divider, Typography, Box, IconButton, TextField, RadioGroup, FormControlLabel, Snackbar } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MuiAlert from '@mui/material/Alert'


// Components
import DialogHeader from '../../../components/DialogHeader'
import BegerzRadio from '../../../components/styled-ui/BegerzRadio'
import { useKarma } from '../../../components/KarmaFlower2'
import WaitOverlay from '../../../components/WaitOverlay'

// Dialogs
import AccountsDialog from '../../../components/dialogs/AccountsDialog'
import ErrorDialog from '../../../components/ErrorDialog'

// Utils
import currFormat from '../../../utils/currFormat'
import roundNumber from '../../../utils/roundNumber'

// Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

// APIs
import { useApi } from '../../../apis/privateApiCall'

// ---------------------------------------------

// Main component
export default function StepTwo(props) {
  // Platform fees
  const PLATFORM_FEES             = process.env.REACT_APP_PLATFORM_FEE * .01
  const PLATFORM_DONATION_FEES    = process.env.REACT_APP_PLATFORM_DONATION_FEE * .01
  const MERCHANT_DISCOUNT_RATE    = process.env.REACT_APP_DISCOUNT_RATE
  const MERCHANT_TRANSACTION_FEE  = process.env.REACT_APP_TRANSACTION_FEE

  // Vars
  const begId         = props.data._id
  const userId        = localStorage.getItem('userId')
  const setDialog     = props.setDialog
  const activeDialog  = props.activeDialog
  const theme         = useTheme()
  const fullScreen    = useMediaQuery(theme.breakpoints.down('md'))
  const borderColor   = theme.palette.gray.light
  const color         = theme.palette.primary.main
  const border        = { border:'1px solid', borderColor:borderColor, borderRadius:'4px' }
  const { 
    getKarmaImage,
    karmaImage,
    loading
  } = useKarma()
  const { execute } = useApi()
  const { 
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors }
  } = useForm({mode: 'all'})
  
  const defaultErrorMessage = 'Error updating payment method'

  // ---------------------------------------------
  
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  }) 
  
  // Dialog functions

  // close the dialog and reload the data
  const closeAndSave = async () => {
    setShowAlert(true)
    setDialogProps(prev => ({...prev, open:false}))
    fetchDefaultMethod(userId)
  }

  // close the dialog and reload the data
  const cancelDialog = async () => {
    setDialogProps(prev => ({...prev, open:false}))
  }

  // update or select withdrawl accounts
  const handleOpenAccounts = () => {
    setDialogProps(prev => ({
      ...prev,
      open:true,
      closeAndSave: closeAndSave,
    }))
  }
 
  // events
  const handleRadioChange = (event) => {
    setInputs({...inputs, [event.target.name]: event.target.value})
  }

  // ---------------------------------------------

  // State
  const [inputs, setInputs] = useState({ 
    chipinType:"random",
    link:"",
    charityId: 'none'
   })

  const [alertErrorMessage, setAlertErrorMessage] = useState(defaultErrorMessage)
  const [defaultMethod, setDefaultMethod] = useState({})
  const [waitOpen, setWaitOpen] = useState(true)
  // eslint-disable-next-line
  const [showAlert, setShowAlert] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [error, setError] = useState({
    message:'',
    errorCode: '',
    title: ''    
  })
  
  const [dialogProps, setDialogProps] = useState({open:false, cancelDialog:cancelDialog})
  const [totals, setTotals] = useState({})
  const [earnedKarma, setEarnedKarma] = useState(0)

  // ---------------------------------------------


  // Methods

  // API Methods

  // get the default paymethod
  const fetchDefaultMethod = async (userId) => {
    // set api options
    const options =  {
      method: 'get',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/users/${userId}/paymethods?sort=-default&page=1&limit=1`
    }
    // call the api and show an alert
    try { 
      const data = await execute({}, options)
      setDefaultMethod(data?.results[0])
    } catch(error) {
      throw error
    }
  }

  // call api for updated beg data
  const fetchBeg = async (begId) => {
    const options =  {
      method: 'get',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/begs/${begId}`
    }
    try {
      return await execute({}, options)
    } catch(error) {
      throw error
    } 
  }

  // call api for processing fees paid and total settled amount
  const createWitdrawal = async (data) => {
    if (!defaultMethod?._id) {
      setAlertErrorMessage('Please edit deposit settings to select a default account')
      setShowErrorAlert(true)
      return false
    }
    const options =  {
      method: 'post',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/withdrawals`
    }
    // create the payload to send to the api server
    const payload = {
      userId: userId,
      begId: begId,
      charityId: inputs?.charityId,
      amountRaised: (data.amountRaised).toFixed(2),
      chipInAmount: (data.chipin).toFixed(2),
      chipInType: inputs.chipinType,
      donationAmount: data.donation.toFixed(2),
      paymethodId: defaultMethod?._id,
      chipInLink: inputs?.link
    }

    if (!payload.chipInLink) delete payload['chipInLink']
    if (payload.charityId === "none") delete payload['charityId']

    try {
      await execute(payload, options)
      setDialog(3)
    } catch(error) {
      setError(prev => ({...prev,
        message: error.message,
        errorCode: '000',
        title: 'Error processing withdrawal'
      }))
    }
  }


  // ---------------------------------------------

  const handleAlertClose = () => {
    setShowAlert(false)
  }
  
  const handleErrorAlertClose = () => {
    setShowErrorAlert(false)
    setAlertErrorMessage(defaultErrorMessage)
  }  
  
  // set the selected charity
  const handleCharitySelect = (event) => {
    if (event.target.value === 'none') {
      setValue("donationAmount", '')
    }
    setInputs(prev => ({...prev, charityId:event.target.value}))
  }


  // strip the begId from the link and verify the status
  const validateBegLink = async (link) => {
    // current begId
    const thisBeg = props.data._id

    // get the begId from the url    
    const links = link.split("/")
    const begId = links[links.length - 1]

    // make sure this same beg wasn't selected
    if (thisBeg === begId) {
      throw new Error("Please choose a different raise")
    }

    // make sure link is and object
    if (begId.length !== 24) {
      throw new Error('The raise link is invalid')
    } 

    // validate the beg exists and is active
    try {
      const beg = await fetchBeg(begId)
      if (beg?.status !== 'active') {
        throw new Error('Please choose an active raise')  
      }
    } catch (error) {
      throw new Error('The raise link is invalid')
    } 
    return begId
  }

  // create the withdrawal and go to next step
  const onSubmit = async () => {
    calcTotals()
    try {
      // validate the contribution beg link
      if (inputs.chipinType === 'link') {
        totals.chipInBegId = await validateBegLink(linkRef?.current.value)
        await createWitdrawal(totals)
      } else {
        delete totals.chipInBegId
        await createWitdrawal(totals)
      }
    } catch (error) {
      setError(prev => ({...prev,
        message: error.message,
        errorCode: '000',
        title: 'Invalid raise link'
      }))
    }
  }

  // set initial values after data is loaded
  const initTotals = (data) => {
    const amountRaised    = data?.amountRaised || 0
    const begerzDonation  = roundNumber(amountRaised * PLATFORM_DONATION_FEES, 2)
    const begerzFees      = roundNumber(amountRaised * (PLATFORM_FEES + PLATFORM_DONATION_FEES), 2)
    const merchantFees    = roundNumber(parseFloat(amountRaised) * parseFloat(MERCHANT_DISCOUNT_RATE * .01), 2) + + parseFloat(MERCHANT_TRANSACTION_FEE)

    // set the totals
    setTotals({
      amountRaised:   amountRaised,
      begerzFees:     begerzFees,
      begerzDonation: begerzDonation,
      merchantFees:   merchantFees,
      donation:       0,
      chipin:         0,
      payable:        amountRaised - (begerzFees + merchantFees),
      withdrawal:     amountRaised - (begerzFees + merchantFees)
    })

    // save the amount on common state
    props.setTotalWithdrawal(totals.withdrawal)
  }

  // ---------------------------------------------

  // Calculations

  // function calculate payouts
  const calcTotals = (newValues) => {
    // combine updated values with current values
    const calcs = {...totals, ...newValues}

    // get new withdrawal, make sure it is a positive calue
    let withdrawal = calcs.payable - (calcs.donation + calcs.chipin)
    if (withdrawal < 0) withdrawal = 0

    calcs.maxRemaining = calcs.payable - (calcs.donation + calcs.chipin)

    // update the calcs
    calcs.withdrawal = withdrawal

    // calculate the karma earned
    setEarnedKarma((20 * (calcs.donation + calcs.chipin)) || 0)

    // update the state
    setTotals(prev => ({ ...prev, ...calcs }))

    props.setTotalWithdrawal(withdrawal)
  }

  // ---------------------------------------------

  // Watch field hooks

  // assign ref to elements that need updated
  const donationRef = useRef()
  const chipinRef   = useRef()
  const linkRef     = useRef()

  // use a react-form watch for chipin amount changes
  const chipinWatch = useWatch({ control, name:'chipinAmount' })

  // use a react-form watch for charity amount changes
  const donationWatch = useWatch({ control, name:'donationAmount' })


  // ---------------------------------------------

  // Effects

  // get initial data via api
  useEffect(() => {
    // call api for updated beg data and calcc totals
    const initData = async (begId) => {
      try {
        let data = await fetchBeg(begId)
        initTotals(data)
      } catch(error) {
        throw error
      } finally {
        await getKarmaImage(localStorage.getItem('karma'))
        await fetchDefaultMethod(userId)
        setWaitOpen(false)
      }
    }
    setWaitOpen(true)
    initData(begId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[begId])


  // charity amount change event
  useEffect(() => {
    const amount = parseFloat(donationRef?.current?.value) || 0
    calcTotals({donation: amount})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[donationWatch])


  // chipin amount change event
  useEffect(() => {
    const amount = parseFloat(chipinRef?.current?.value) || 0
    calcTotals({chipin: amount})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chipinWatch])

  // ---------------------------------------------

  return (
    <>
      {/* accounts dialog */}

      { waitOpen && <WaitOverlay />}
      { dialogProps?.open && <AccountsDialog {...dialogProps} /> }
      { error?.message && <ErrorDialog setError={setError} {...error} />}

      <Dialog
        maxWidth="sm"
        open={activeDialog === 2 && !waitOpen}
        fullScreen={fullScreen}
        fullWidth
      >
        <DialogContent sx={{ mb: 2 }}>
          <Box sx={{ textAlign:'left' }}>
            <DialogHeader sx={{ textAlign:'left', mb:2 }}>
              End &amp; Withdraw
            </DialogHeader>
          </Box>        

          <IconButton
            onClick={ () => { setDialog(0) }}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 24,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <Divider sx={{ mb:2 }}></Divider>


          {/* info section */}
        
          <form onSubmit={handleSubmit(onSubmit)}>

            <Grid container alignItems="center" justifyContent="space-between" alignContent="center">
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12} mb={1.5}>
                    <Typography variant='h6'>
                      Total Raised
                    </Typography>
                  </Grid>

                  <Grid item xs={12} >
                    <Typography variant="subtitle1">
                      Platform Fee
                    </Typography>
                  </Grid>

                </Grid>
              </Grid>

              {/* begerz fee */}

              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h7" sx={{ textAlign: "right", fontWeight:"600" }}>
                      ${ totals && currFormat((totals?.amountRaised || 0.00), 2 ) }
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography 
                      variant="subtitle1"
                      sx={{ textAlign: "right" }}>
                      (${ currFormat(totals.amountRaised * PLATFORM_FEES, 2) })
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* donation info box */}

              <Grid item xs={12} sx={{ mb:.5, p:1, backgroundColor:"#A9E4ED" }}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant='subtitle2'>
                      { `Begerz donates ${PLATFORM_DONATION_FEES * 100}% directly back to partner charities. For this raise $${ currFormat(totals.begerzDonation, 2) } will go to charities.` }
                      <Link to=""
                        style={{ marginLeft:"8px", textAlign:"right", color:color }}
                        onClick={ () => {window.open('/help', '_blank')} }
                      >
                        Learn More
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} mt={.5}>
                <Typography variant="subtitle1">
                  { `Merchant Fee (${MERCHANT_DISCOUNT_RATE}% + $0${MERCHANT_TRANSACTION_FEE})` }
                </Typography>
              </Grid>

            </Grid>


          {/* charity donation */}

          <Grid container alignItems="center" justifyContent="space-between" alignContent="center" sx={{ mt:1, p:2, pt:1, pb:1, border }}>
            <Grid item sx={{ mb:1}} >
              <Typography variant="h6" >
                Donate more to the following charity:
              </Typography>
            </Grid>
            <Grid item >
              {/* <InfoOutlinedIcon /> */}
            </Grid>

            <Grid container alignContent="center" justifyContent="space-between" spacing={2}>
              <Grid item xs={8} >
                <FormControl fullWidth>
                  <InputLabel id="chaity-select-label">Charity</InputLabel>
                  <Select
                    labelId="charity-select-label"
                    name="charitySelect"
                    id="charitySelect"
                    label="Charity"
                    value={inputs.charityId}
                    onChange={handleCharitySelect}
                  >
                    <MenuItem value={"none"}>None</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  name="donationAmount"
                  label="Amount"
                  inputRef={donationRef}
                  disabled={inputs.charityId === "none"}
                  autoComplete="donationAmount"
                  fullWidth
                  error={!!errors.donationAmount || null}
                  helperText={ errors?.donationAmount?.message || <br /> }
                  {...register('donationAmount', {
                    min: { value: 1, message: "Minimum is $1.00" },
                    max: { value: (totals.maxRemaining + totals.donation), message: `Maximum is $${ currFormat((totals.maxRemaining + totals.donation), 2)}` },
                    maxLength: { value:8, message: '8 characters max' },
                    pattern: {
                      value: /^[0-9.]*$/,
                      message: 'Numbers only'
                    }
                  })}
                  />
              </Grid>
            </Grid>
          </Grid>


          {/* karma section */}

          <Grid container sx={{ mt:2, border }}>
            <Grid item xs={12}>
              <Grid container
                alignItems="center"
                justifyContent="space-between"
                sx={{ p:1, pt:.5, backgroundColor:theme.palette.gray.light, borderTopRightRadius:4, borderTopLeftRadius:4 }}
              >
                <Grid item >
                  <Typography variant="h6" >
                    { !loading && <img height="38px" width="38px" src={require(`../../../assets/icons/karma-levels/${karmaImage}`)} alt="karma flower" style={{ verticalAlign:"middle", marginRight:"4px" }} /> }
                    Karma Points Earned:
                  </Typography>
                </Grid>
                <Grid item >
                  {/* <InfoOutlinedIcon /> */}
                </Grid>
              </Grid>
            </Grid>

            {/* karma status grid */}

            <Grid container alignContent="center"  spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" spacing={1} p={2}>
                  <Grid item xs={8}>
                    Your Karma Points
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign:"right" }}>
                    { currFormat(localStorage.getItem('karma')) } points
                  </Grid>
                  <Grid item xs={8}>
                    Karma Points Earned
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign:"right" }}>
                    { currFormat(earnedKarma) } points
                  </Grid>
                  <Grid item xs={8}>
                    Karma Balance
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign:"right" }}>
                    { currFormat( parseInt(localStorage.getItem('karma')) + parseInt(earnedKarma)) } points
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>



          {/* chip in */}

          <Grid container alignItems="center" justifyContent="space-between" alignContent="center" sx={{ mt:2, p:2, pt:1, pb:1, border }}>
            <Grid item >
              <Typography variant="h6" sx={{ mb:1 }} >
                Pay It Forward:
              </Typography>
            </Grid>
            <Grid item >
              {/* <InfoOutlinedIcon /> */}
            </Grid>

            {/* community amount */}

            <Grid container alignContent="center" justifyContent="space-between" spacing={0}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1" sx={{ mt:.5, p:1.5, fontWeight:550, backgroundColor:theme.palette.gray.light}} >
                      Donate back to the Begerz community
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="chipinAmount"
                      inputRef={chipinRef}
                      label="Amount"
                      autoComplete="chipinAmount"
                      fullWidth
                      error={!!errors.chipinAmount || null}
                      helperText={ errors?.chipinAmount?.message || <br /> }
                      {...register('chipinAmount', {
                        min: { value: 1, message: "Minimum is $1.00" },
                        max: { value: (totals.maxRemaining + totals.chipin), message: `Maximum is $${ currFormat((totals.maxRemaining + totals.chipin), 2)}` },
                        maxLength: { value:8, message: '8 characters max' },
                        pattern: { value: /^[0-9.]*$/, message: 'Numbers only' }
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <FormControl size="small" margin="none">
                  <RadioGroup
                    name="chipinType"
                    id="chipinType"
                    value={inputs.chipinType}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel sx={{ color:theme.palette.gray.main }}
                      value="random"
                      control={<BegerzRadio size="small" sx={{ color:color, '&.Mui-checked': {color: color} }} />}
                      label="Chip-in to a random raise" 
                    />
                    <FormControlLabel sx={{ color:theme.palette.gray.main }}
                      value="link"
                      control={<BegerzRadio size="small" sx={{ color:color, '&.Mui-checked': {color: color} }} />}
                      label="Specify a raise to support"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  inputRef={linkRef}
                  disabled={inputs.chipinType === "random"}
                  name="link"
                  id="link"
                  label="Raise Link"
                  margin="normal"
                  autoComplete="empty-link"
                  fullWidth
                  error={!!errors.link || null}
                  helperText={ errors?.link?.message || <br /> }
                  {...register('title', {
                    maxLength: { value:200, message: 'Link must be 200 characters or less' },
                    pattern: {
                      value : /^(http|https|:\/\/|\.|@){2,}(localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\S*:\w*@)*([a-zA-Z]|(\d{1,3}|\.){7}){1,}(\w|\.{2,}|\.[a-zA-Z]{2,3}|\/|\?|&|:\d|@|=|\/|\(.*\)|#|-|%)*$/gum,
                      message: 'Must be a valid address'
                    }}
                  )}
                />
              </Grid>
            </Grid>
          </Grid>


          {/* withdrawl section */}

          <Grid container spacing={1} mt={3} mb={2} alignItems="center">
            <Grid item xs={6}>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Typography variant='h6'>
                    Total Withdrawal
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* total withdrawl */}

            <Grid item xs={6}>
              <Grid container justifyContent="space-between" alignItems="flex-end">
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    sx={{ textAlign:"right", fontWeight:700 }}
                    >
                    ${ totals && currFormat( totals.withdrawal, 2 ) }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* withdrawl method */}

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography variant='h6'>
                    { defaultMethod && 
                    <>
                      { defaultMethod.paytype?.toUpperCase() } | {defaultMethod?.description}
                    </>
                    }
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="link"
                    sx={{ p:0, color:color }}
                    onClick={handleOpenAccounts}
                  >
                    Edit Deposit Settings
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          </Grid>


          <Grid container spacing={2} mt={1} >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ mt:1, mb:1, fontWeight: 700 }}
                onClick={ () => { setDialog(0) } }
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={totals.amountRaised <= 0}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt:1, mb:1, fontWeight: 700 }}
              >
                End &amp; Withdraw Funds
              </Button>
            </Grid>
          </Grid>

          </form>

        </DialogContent>
      </Dialog>

      <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
          Settings have been saved!
        </Alert>
      </Snackbar>

      <Snackbar open={showErrorAlert} autoHideDuration={6000} onClose={handleErrorAlertClose}>
        <Alert onClose={handleErrorAlertClose} severity="error" sx={{ width:'100%' }}>
          { alertErrorMessage }
        </Alert>
      </Snackbar>      
    </>
  )
}
import React from 'react'
import { useParams } from 'react-router-dom'
import WaitOverlay from '../components/WaitOverlay'
import ErrorDialog from '../components/ErrorDialog'
import Container from '@mui/material/Container'
import { Button } from '@mui/material'

// Custom components
import BegPagination from '../components/raises/BegPagination'
import SuccessPagination from '../components/success-stories/SuccessPagination'
import BottomCards from '../components/BottomCards'
import Header from '../components/profile/Header'
import SectionDivider from '../components/styled-ui/SectionDivider'
import SectionHeaderContainer from '../components/styled-ui/SectionHeaderContainer'
import AccessPageLower from '../components/AccessPageLower'


// Main Component
export default function Profile() {
  // Vars
  const { userId } = useParams()

  // State
  const [waitOpen, setWaitOpen] = React.useState(true)
  const [canLoad, setCanLoad] = React.useState({ active: true})  
  const [hasError, setHasError] = React.useState({
    status: false,
    message: '',
    code: 500
  })


  // Methods

  // handle load order of data sections
  const handleLoaded = (index) => {
    switch (index) {
      case 'active':
        setCanLoad(prev => ( {...prev, success:true} ))
        break
      default:
        setWaitOpen(false)
    }
  }

  // properties for active raises
  const activeProps = {
    doneLoading: handleLoaded,
    type: 'active',
    url: `/api/v1/begs/users/${userId}?status=active&publishType=public&sort=-createdAt`,
    pageSize:12,
    paginationType:"more",
    adverts: false,
    divider:<><SectionDivider /></>
  }
  
  // properties for success stories
  const successProps = {
    doneLoading: handleLoaded,
    type: 'success',
    url: `/api/v1/success/users/${userId}?sort=-createdAt`,
    pageSize:12,
    paginationType:"more",
    adverts: false,
    divider:<><SectionDivider /></>
  }


  return (
    <>
      { waitOpen && <WaitOverlay />}
      { hasError.status && <ErrorDialog message={hasError.message} setError={setHasError} errorCode={hasError.code} title={'Error retrieving begs'} /> }

      <Container component="main" maxWidth="md">

        { !waitOpen && <Header data={userId} /> }

        <div style={{ marginTop:'2em' }} />

        {/* active begs */}
        <SectionHeaderContainer mb={2} to="">Active Raises</SectionHeaderContainer>
        { canLoad?.active && 
          <BegPagination {...activeProps}>
            <SectionDivider>
              <Button variant='outlined' sx={{ mt:-4, backgroundColor:"white", "&:hover": { backgroundColor: "white !important" } }}>
                View More
              </Button>
            </SectionDivider>
          </BegPagination>
        }

        {/* success stories */}
        <SectionHeaderContainer mb={2} to="">Success Stories</SectionHeaderContainer>
        { canLoad?.success && 
          <SuccessPagination {...successProps}>
            <SectionDivider>
              <Button variant='outlined' sx={{ mt:-4, backgroundColor:"white", "&:hover": { backgroundColor: "white !important" } }}>
                View More
              </Button>
            </SectionDivider>
          </SuccessPagination> 
        }

        <AccessPageLower />

      </Container>
      <BottomCards />
    </>
  )
}
import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Button, Divider, Grid, Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

// Custom components
import ProfileAvatar from '../components/ProfileAvatar'
import NumberBox from '../components/NumberBox'
import { useKarma } from '../components/KarmaFlower'
import ExploreCircles from '../components/ExploreCircles'
import Adverts from '../components/advertisement'
import BegerzGivesBack from '../components/BegerzGivesBack'
import BottomCards from '../components/BottomCards'
import BegPagination from '../components/raises/BegPagination'
import SuccessPagination from '../components/success-stories/SuccessPagination'

// Error Components
import WaitOverlay from '../components/WaitOverlay'
import ErrorDialog from '../components/ErrorDialog'

// Utils
import currFormat from '../utils/currFormat'

// Apis
import { useApi as privateApiCall } from '../apis/privateApiCall'

// Icons
import MoneyBag from '../assets/icons/moneybag/half.svg'
import Donate from '../assets/icons/donate.svg'


// Main component
export default function Profile() {
  // Vars
  const navigate = useNavigate()
  const userId = localStorage.getItem('userId')
  const username = localStorage.getItem('username')
  const profileImage = localStorage.getItem('profileImage')
  const karma = localStorage.getItem('karma')
  const theme = useTheme()

  // APIs
  const { execute: executeProfile } = privateApiCall()

  // State
  const [hasError, setHasError] = React.useState({
    status: false,
    message: '',
    code: 500
  })
  
  const [waitOpen, setWaitOpen] = React.useState(true)
  const [canLoad, setCanLoad] = React.useState({ active: true})

  const [profile, setProfile] = React.useState({
    chipIns:0,
    comments:0,
    donations:0,
    followers:0,
    following:0,
    karma:0,
    profileImage: localStorage.getItem('profileImage')
  })

  // Methods
  const handleEditProfile = () => {
    navigate('/myprofile/edit')
  }

  // handle load order of data sections
  const handleLoaded = (index) => {
    switch (index) {
      case 'active':
        setCanLoad(prev => ( {...prev, ended:true} ))
        break
      case 'ended':
        setCanLoad(prev => ( {...prev, completed:true} ))
        break
      case 'completed':
        setCanLoad(prev => ( {...prev, success:true} ))
        break
      default:
        setWaitOpen(false)
    }
  }


  // Effect to get updated profile
  React.useEffect(() => {
    const fetchProfile = async (userId) => {
      // set api options
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/users/${userId}`
      }
      // call the api and show an alert
      try {
        setProfile(await executeProfile({}, options))
      }
      catch(error) {        
        throw error
      }
    }
    fetchProfile(userId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <>
      { waitOpen && <WaitOverlay />}
      { hasError.status && <ErrorDialog message={hasError.message} setError={setHasError} errorCode={hasError.code} title={'Error retrieving begs'} /> }

      <Container component="main" maxWidth="md">
        <Typography
          variant="h4"
          sx={{ color:'white', textAlign:'left', mb:2 }}
        >
          { username }
        </Typography>

        <Paper elevation={4}>
          <Box sx={{ display:'flex', flexDirection:'column', alignItems:'left', padding:3 }} >


            <Grid container justifyContent="center" alignItems="center" spacing={4}>
              
              {/* profile */}

              <Grid item xs={4}>
                <Paper elevation={1} sx={{ minHeight:'198px', minWidth:'198px', borderRadius:'4px', padding:'6px' }}>
                  <ProfileAvatar 
                    src={profileImage}
                    alt={username}
                    sx={{ marginLeft:'auto', marginRight:'auto', width:186, height:186, fontSize:188, borderRadius:'4px' }}>
                  </ProfileAvatar>
                </Paper>
              </Grid>


              {/* followers / karam */}

              <Grid item xs>
                <Grid container rowSpacing={3}>

                  <Grid item xs >
                    <Grid container spacing={4}>

                      <Grid item xs>
                        <NumberBox variant="h2" sx={{ textAlign:'center' }} number={ profile?.followers } />
                        <Typography variant="body2" sx={{ textAlign:'center' }}>Followers</Typography>
                      </Grid>  
                      
                      <Grid item xs>
                        <NumberBox variant="h2" sx={{ textAlign:'center' }} number={ profile?.following } />
                        <Typography variant="body2" sx={{ textAlign:'center' }}>Following</Typography>
                      </Grid>  


                      {/* karma image and title */}
                      
                      <Grid container item xs={8} justifyContent="flex-end" alignItems="center">
                        <Grid item>
                          { !waitOpen && <img height="auto" width="64px" alt="karma-flower" src={profile?.karmaLevel?.iconPath} /> }
                        </Grid>
                        <Grid item>
                          <Typography ml={1} variant="caption">
                            <NumberBox variant="caption" sx={{ display:"inline-block"}}>${profile?.karma || 0}</NumberBox> points
                          </Typography>
                          <Typography ml={1} mt={.5} variant="caption">
                            { profile?.karmaLevel?.title }
                          </Typography>
                        </Grid>
                      </Grid>  

                    </Grid>
                  </Grid>

                  {/* second row */}
                  <Grid item xs={12} >
                    <Button fullWidth variant="contained" onClick={ handleEditProfile }>
                      Edit Profile
                    </Button>
                  </Grid>

                  {/* thrid row */}
                  <Grid item xs={12} >
                    <Grid container spacing={4} >
                      <Grid item xs>
                        <Grid container spacing={1} alignItems="center" justifyContent="flex-end" >
                          <Grid item xs={4}>
                            <img style={{ marginTop:"8px", width:"27px", height:"33px" }} alt="money bag" src={profile?.iconPathRaised}></img>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant='body2'>Raised</Typography>
                            <Typography variant='h6'>${ profile?.totalRaised && currFormat(profile?.totalRaised) }</Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs>
                        <Grid container spacing={1} alignItems="center" justifyContent="flex-end" >
                          <Grid item xs={4}>
                            <img style={{ marginTop:"8px", width:"27px", height:"33px" }} alt="donation" src={Donate}></img>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant='body2'>Donated</Typography>
                            <Typography variant='h6'>${ profile?.totalDonated && currFormat(profile?.totalDonated) }</Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs>
                        <Grid container spacing={1} alignItems="center" justifyContent="flex-end" >
                          <Grid item xs={4}>
                            <img style={{ marginTop:"8px", width:"27px", height:"33px" }} alt="money bag" src={profile?.iconPathBegs}></img>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant='body2'>Goals</Typography>
                            <Typography variant='h6'>${ profile?.totalGoals && currFormat(profile?.totalGoals) }</Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs>
                        <Grid container spacing={1} alignItems="center" justifyContent="flex-end" >
                          <Grid item xs={4}>
                            <img style={{ marginTop:"8px", width:"27px", height:"33px" }} alt="money bag" src={profile?.iconPathChippedIn}></img>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant='body2'>Chip-Ins</Typography>
                            <Typography variant='h6'>${ profile?.totalChippedIn }</Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Box>
        </Paper>

        <div style={{ marginTop:'2em' }} />

        {/* active begs */}
        { canLoad?.active && profile?._id && <BegPagination editLink={true} doneLoading={handleLoaded} type={'active'} url={`/api/v1/begs/users/${profile?._id}?status=active&sort=-createdAt`} title={'Active Begs'} pageSize={12} /> }

        {/* ended (expired) begs */}
        { canLoad?.ended && profile?._id && <BegPagination editLink={true} doneLoading={handleLoaded} type={'ended'} url={`/api/v1/begs/users/${profile?._id}?status=ended&sort=-createdAt`} title={'Expired Begs'} pageSize={12} />}

        {/* completed begs */}
        { canLoad?.completed && profile?._id && <BegPagination editLink={true} doneLoading={handleLoaded} type={'completed'} url={`/api/v1/begs/users/${profile?._id}?status=completed&sort=-createdAt`} title={'Completed Begs'} pageSize={12} /> }
        
        {/* success stories */}
        { canLoad?.success && profile?._id && <SuccessPagination editLink={true} doneLoading={handleLoaded} type={'success'} url={`/api/v1/success/users/${profile?._id}?sort=-createdAt`} title={'Success Stories'} pageSize={12} /> }


        <ExploreCircles mb={4} />
        <Adverts style={{ marginBottom:'34px'}} />
        <Divider style={{ background:theme.palette.primary.main, marginBottom:'24px' }}></Divider>
        <BegerzGivesBack />

      </Container>

      <BottomCards />
    </>
  )
}
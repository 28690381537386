import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'

// Custom components
import PageTitle from '../styled-ui/PageTitle'
import NumberBox from '../NumberBox'
import ProfileAvatar from '../ProfileAvatar'

// Utils
import currFormat from '../../utils/currFormat'

// APIs
import { useApi as getFollowers } from '../../apis/getFollowers'
import { useApi as getAccount } from '../../apis/getAccount'

// Icons
import Donate from '../../assets/icons/donate.svg'


// Main component
export default function Header(props) {
  // Vars
  const profileUserId = props.data
  const navigate = useNavigate()

  // State
  const [waitOpen, setWaitOpen] = useState(true)
  const [profile, setProfile] = useState(false)
  const [followButton, setFollowButton] = useState({
    disabled: true,
    title: 'Follow',
    followId: null
  })
 
  // APIs
  const { execute, create, remove, controller } = getFollowers()
  const accountExecute = getAccount().execute


  // Methods

  // unfollow another user
  const unFollow = async() => {
    try {
      await remove({id:followButton.followId})
      getData()
    } catch (error) {}
  }
  
  // follow another user
  const follow = async() => {
    try {
      await create({ userId:profileUserId, followerId:localStorage.getItem('userId') })
      getData()
    } catch (error) {}
  }

  // toggle follow another user
  const handleFollow = () => {
    if (followButton.followId !== null) unFollow()
    else follow()
  }


  // API call to get account data
  const fetchAccount = async(query, callback) => {
    try {
      const data = await accountExecute(query)
      callback(prev => { return {...prev, ...data} })
    } catch (error) {
      if (error.status === 404) navigate('/404')
    }
  }

  // handle API call to check if user is a follower
  const fetchFollowers = async(query) => {
    try {
      const data = await execute(query)
      const found = data.pagination.records
      if (found > 0) setFollowButton(prev => ( {...prev, title:'Unfollow', disabled:false, followId: data.results[0]?._id} ))
      else setFollowButton(prev => ( {...prev, title:'Follow', disabled:false, followId:null} ))
    } 
    catch (error) {
      if (error.status === 404) setFollowButton(prev => ({...prev, title:'Follow', disabled:false, followId:null}))
    } finally { setWaitOpen(false)}
  }

  // function to call API functions
  const getData = async () => {
    try {
      await fetchAccount(`${profileUserId}?status=active`, setProfile)
      // only do a lookup if this is not the same user
      if (profileUserId === localStorage.getItem('userId') || !localStorage.getItem('userId')) setFollowButton(prev => ({...prev, title:'Follow', disabled:true, followId:null}))
      else fetchFollowers(`${profileUserId}/followers?followerId=${localStorage.getItem('userId')}`)
    } 
    catch (error) {} 
    finally { setWaitOpen(false) }
  }


  // Effects
  // effect to fetch data on page load
  useEffect(() => {
    getData()
    return controller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <>
      <PageTitle >{ profile && `${profile.username}` }</PageTitle>

      <Paper elevation={4}>
        <Box sx={{ display:'flex', flexDirection:'column', alignItems:'left', padding:3 }} >

          <Grid container spacing={4}>

            {/* profile image */}
            <Grid item xs={12} sm={3}>
              <Paper elevation={1} sx={{ minHeight:'198px', minWidth:'198px', borderRadius:'4px', padding:'6px' }}>
                { !waitOpen && 
                <ProfileAvatar 
                  src={ profile?.currentImage || profile?.profileImage } alt={ profile?.username } 
                  sx={{ ml:'auto', mr:'auto', width:186, height:186, fontSize:188, borderRadius:'4px' }}>
                </ProfileAvatar>
                }
              </Paper>
            </Grid>

            <Grid item xs={12} sm={8} md={9}>
              <Grid container rowSpacing={3}>

              {/* first row */}
              <Grid item xs={12} >
                <Grid container spacing={4}>

                  <Grid item xs={2}>
                    <NumberBox variant="h2" sx={{ textAlign:'center' }} number={profile.followers} />
                    <Typography variant="body2" sx={{ textAlign:'center' }}>Followers</Typography>
                  </Grid>  
                  
                  <Grid item xs={2}>
                    <NumberBox variant="h2" sx={{ textAlign:'center' }} number={profile.following} />
                    <Typography variant="body2" sx={{ textAlign:'center' }}>Following</Typography>
                  </Grid>  


                  {/* karma image and level */}

                  <Grid container item xs={8} justifyContent="flex-end" alignItems="center">
                    <Grid item>
                      { !waitOpen && <img height="auto" width="64px" alt="karma-flower" src={profile?.karmaLevel?.iconPath} /> }
                    </Grid>
                    <Grid item>
                      <Typography ml={1} variant="caption">
                        <NumberBox variant="caption" sx={{ display:"inline-block"}}>${profile?.karma || 0}</NumberBox> points
                      </Typography>
                      <Typography ml={1} mt={.5} variant="caption">
                        { profile?.karmaLevel?.title }
                      </Typography>
                    </Grid>
                  </Grid>  

                </Grid>
              </Grid>

                {/* second row */}
                <Grid item xs={12} >
                  <Button disabled={ followButton.disabled } fullWidth variant="contained" onClick={ handleFollow }>
                    { followButton.title }
                  </Button>
                </Grid>

                {/* thrid row */}
                <Grid item xs={12} >
                  <Grid container spacing={4} >

                    <Grid item xs={3}>
                      <Grid container spacing={1} alignItems="center" justifyContent="flex-end" >
                        <Grid item xs={4}>
                          <img style={{ marginTop:"8px", width:"27px", height:"33px" }} alt="money bag" src={profile?.iconPathRaised}></img>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant='body2'>Raised</Typography>
                          <Typography variant='h6'>${ profile?.totalRaised && currFormat(profile?.totalRaised) }</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={3}>
                      <Grid container spacing={1} alignItems="center" justifyContent="flex-end" >
                        <Grid item xs={4}>
                          <img style={{ marginTop:"8px", width:"27px", height:"33px" }} alt="donation" src={Donate}></img>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant='body2'>Donated</Typography>
                          <Typography variant='h6'>${ profile?.donations && currFormat(profile?.donations)}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={3}>
                      <Grid container spacing={1} alignItems="center" justifyContent="flex-end" >
                        <Grid item xs={4}>
                          <img style={{ marginTop:"8px", width:"27px", height:"33px" }} alt="money bag" src={profile?.iconPathBegs}></img>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant='body2'>Goals</Typography>
                          <Typography variant='h6'>${ profile?.totalGoals && currFormat(profile?.totalGoals) }</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={3}>
                      <Grid container spacing={1} alignItems="center" justifyContent="flex-end" >
                        <Grid item xs={4}>
                          <img style={{ marginTop:"8px", width:"27px", height:"33px" }} alt="money bag" src={profile?.iconPathChippedIn}></img>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant='body2'>Chip-Ins</Typography>
                          <Typography variant='h6'>${ profile?.totalChippedIn && currFormat(profile?.totalChippedIn) }</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
                
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  )
}

import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Grid, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import { format, isBefore } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

// Icons
import LaunchIcon from '@mui/icons-material/Launch'
import currFormat from '../../../utils/currFormat'


// Main component
export default function DetailsForm(props) {
  // Vars
  let data = props.data
  const margin = "normal"
  const theme = useTheme()
  const navigate = useNavigate()
  const color = theme.palette.primary.main
  const itemStyle = { fontSize:"13px", fontWeight:"550" }
  const dateFormat = 'MM/dd/yyyy'

  const MIN_GOAL = process.env.REACT_APP_MIN_GOAL_AMOUNT
  const MAX_GOAL = process.env.REACT_APP_MAX_GOAL_AMOUNT
  const displayMinGoal = `$${currFormat(process.env.REACT_APP_MIN_GOAL_AMOUNT, 0)}`
  const displayMaxGoal = `$${currFormat(process.env.REACT_APP_MAX_GOAL_AMOUNT, 0)}`

  // Validation
  const { 
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      title: data.title,
      goalAmount: data.goalAmount,
      goalDate: data.goalDate,
      status: data.status
    }
  })


  // State
  const [goalDate, setGoalDate] = useState()
  const [minDate, setMinDate] = useState()
  const [maxDate, setMaxDate] = useState()
  const [inputs, setInputs] = useState({
    goalAmount: data.goalAmount,
    title: data.title,
    goalDate: data.goalDate,
    description: data.htmlDescription,
    status: data.status,
    publishType: data.publishType,
    flagStatus: data?.flagStatus || false
  })


  // Methods
  const onSubmit = () => {

  }

  // update the beg status
  const handleStatusChange = (event) => {
    setInputs(prev => ({...prev, status:event.target.value}))
  }

  // update the beg privacy setting
  const handlePrivacyChange = (event) => {
    setInputs(prev => ({...prev, publishType:event.target.value}))
  }

  // make sure date is not before minimum
  const handleDateChange = (newDate) => {
    // if the newDate < minDate, use minDate
    if (isBefore(newDate, minDate)) newDate = minDate

    try {
      newDate = format(newDate, dateFormat)
      setGoalDate(newDate)
      setValue("goalDate", newDate, {
        shouldValidate: true,
        shouldDirty: true
      })
    } catch(err) {}
  }

  const formatCurrency = (event) => {
    const name = event.target.name
    const value = event.target.value
    setValue(name, currFormat(value, 2))
  }


  // Effects

  // Set dates in date picker
  useEffect(() => {
    const goalDate = new Date(data.goalDate)
    handleDateChange(goalDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <Grid item xs={12} mt={2}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        
        {/* top row - title | status, goalAmount */}

        <Grid container spacing={2} alignItems="baseline">
          <Grid item xs={12} md={9}>
            <TextField
              name="title"
              label="Title of Beg"
              required
              autoComplete="begTitle"
              fullWidth
              margin={margin}
              error={!!errors.title || null}
              helperText={errors?.title ? errors.title.message : null}
              inputProps={{ maxLength:48, pattern:/^[A-Za-z0-9 ]+$/ }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => navigate(`/raises/details/${data?._id}`)} >
                    <LaunchIcon />
                  </IconButton>
                )
              }}
              {...register('title', {
                required: "Title is required",
                maxLength: { value: 48, message: 'Title must be 48 characters or less' },
                pattern: {
                  value : /^[A-Za-z0-9 ]+$/,
                  message: 'Title must be alpha numeric characters only'
                }})}
            />          
          </Grid>


          <Grid item xs={12} md={3}>
            <TextField
              name="goalAmount"
              label="Amount"
              required
              autoComplete="goalAmount"
              fullWidth
              margin={margin}
              error={!!errors.goalAmount || null}
              helperText={errors?.goalAmount ? errors.goalAmount.message : null}
              inputProps={{ onBlur: (event) => formatCurrency(event) }}
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}              
              {...register('goalAmount', { 
                required: "Amount is required",
                min: { value: MIN_GOAL, message: `Minimum is ${displayMinGoal}` },
                max: { value: MAX_GOAL, message: `Maximum is ${displayMaxGoal}` },
                pattern: {
                  value: /^[0-9.]*$/,
                  message: 'Numbers only'
                }
              })}
            />
          </Grid>
        </Grid>


        {/* privacy and goal date */}

        <Grid container spacing={2} alignItems="baseline">

          <Grid item xs={12} md={4}>
            <TextField
              select
              fullWidth
              margin={margin}
              label="Status"              
              sx={{ fontSize:"13px", fontWeight:"600" }}
              name="paytypeSelect"
              value={inputs?.status}
              onChange={handleStatusChange}
            >
              <MenuItem sx={itemStyle} value="active">Active</MenuItem> 
              <MenuItem sx={itemStyle} value="blocked">Blocked</MenuItem> 
              <MenuItem sx={itemStyle} value="completed">Completed</MenuItem> 
              <MenuItem sx={itemStyle} value="ended">Ended</MenuItem> 
            </TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              select
              fullWidth
              margin={margin}
              label="Privacy setting"
              sx={{ fontSize:"13px", fontWeight:"600" }}
              name="privacySelect"
              value={inputs?.publishType}
              onChange={handlePrivacyChange}
            >
              <MenuItem sx={itemStyle} value="private">Private</MenuItem> 
              <MenuItem sx={itemStyle} value="unlisted">Unlisted</MenuItem> 
              <MenuItem sx={itemStyle} value="public">Public</MenuItem> 
            </TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <div className='end-date-flex-container'>
              <div className='end-date-flex-item end-date-item-date'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Goal date"
                    name="goalDate"
                    minDate={minDate}
                    maxDate={maxDate}
                    allowSameDateSelection
                    inputFormat={dateFormat}
                    onChange={handleDateChange}
                    value={goalDate || minDate}
                    renderInput={(params) =>
                      <TextField {...params}
                        margin={margin}
                        helperText={errors.goalDate?.message || <br />}
                        sx={{ svg: {color} }}
                        fullWidth
                        error={!!errors.goalDate}
                        {...register('goalDate', {
                          required: "End date is required",
                          max: { 
                            value: maxDate,
                            message: `Max date is 30 days`
                          },
                          min: { 
                            value: minDate,
                            message: 'Cannot be before today'
                          }
                        })}
                      />}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </Grid>

        </Grid>
      </form>
    </Grid>      
  )
}
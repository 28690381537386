import { useState, useRef } from 'react'
import { Button, Grid, Paper, TextField } from '@mui/material'

// Apis
import { useApi } from '../../../apis/privateApiCall'


// Main component
export default function SideNote(props) {
  // Vars
  const withdrawalId = props.withdrawalId
  const refresh = props.refresh
  const { execute } = useApi()

  // State
  const [error, setError] = useState({
    message:'',
    errorCode: '',
    title: ''    
  })

  // Ref to track note content
  const noteRef = useRef()

  // Methods

  // handle post click and sanitize the input before sending it
  const handlePostClick = () => {
    // return if input is empty    
    if (!noteRef.current.value) {
      console.log('empty')
      return false
    }

    // sanitize the string before submitting it
    const string = noteRef.current.value
    const sanitizedString = string.replace(/[^a-zA-Z0-9,!@$%_ *&"\-+=().?]/g, '')
    
    // create the note and clear the input
    createNote(sanitizedString)
    noteRef.current.value = ""
  }


  // call api to save new note
  const createNote = async(content) => {
    try {
      const options =  {
        method: 'post',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/admin/withdrawal-logs`
      }

      // send call to API
      await execute({
        withdrawalId: withdrawalId,
        editorId: localStorage.getItem('userId'),
        action: "note",
        note: content
      }, options)

      // reload the activity log
      await refresh()

    } catch(error) {
      setError(prev => ({...prev,
        message: error.message,
        errorCode: '000',
        title: 'Error updating withdrawal status'
      }))
    }
  }


  return (
    <Paper elevation={1} sx={{ mt:2, p:1, pb:2 }}>

      <Grid container spacing={1} alignItems="center">
        <Grid item xs={8.5} >
          <TextField
            inputProps={{ style: { fontSize:13 }}}
            inputRef={noteRef}
            placeholder="Add note here"
            multiline
            maxRows={2}
          >
          </TextField>
        </Grid>
        <Grid item xs={3.5}>
          <Button
            onClick={handlePostClick}
            variant="contained"
            sx={{ p:0, pt:1, pb:1, borderRadius:"4px" }}
          >
            POST
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}
